<template>
  <div class="table-responsive">
    <div class="float-right">
      <a
        _target="blank"
        class="btn btn-primary text-white"
        v-bind:href="downloadLink"
        title="Download table"
      >
        <span class="align-middle material-icons">save_alt</span>
        <span class="align-middle">&nbsp;Download&nbsp;CSV</span>
      </a>
    </div>

    <table class="table table-striped table-sm table-hover" style="width: 100%;">
      <thead>
        <tr>
          <th>
            <a class="sorting" v-on:click="sortBy('name')" title="Click to sort">Name</a>
          </th>
          <th>
            <a class="sorting" v-on:click="sortBy('label')" title="Click to sort">Label</a>
          </th>
          <th>
            <a class="sorting" v-on:click="sortBy('start')" title="Click to sort">Genomic Start</a>
          </th>
          <th>
            <a class="sorting" v-on:click="sortBy('end')" title="Click to sort">Genomic End</a>
          </th>
          <th>Putative function</th>
          <th>C-terminal cleavage site</th>
          <th>orf1ab start-end</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="p in sorted" v-bind:key="p.label">
          <td>{{p.name}}</td>
          <td>{{p.label}}</td>
          <td>{{p.start}}</td>
          <td>{{p.end}}</td>
          <td>{{ p.funct? p.funct.replace( "~","'") : NA }}</td>
          <td>{{p.clevsite? p.clevsite.replace( "~","'"): NA}}</td>
          <td>{{p.orf1abloc ? p.orf1abloc:NA}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  name: "ProteinsTable",
  props: {
    proteins: Array,
    isnsps: Boolean
  },
  data: function() {
    return {
      sortKey: "label",
      reverse: true,
      NA: "n/a"
    };
  },
  computed: {
    downloadLink: function() {
      return this.$parent.proteinsTable;
    },
    sorted: function() {
      let k = this.sortKey;
      let o = this.reverse ? -1 : 1;
      // let t = this.isnsps;

      let compare = function(a, b) {
        let res;
        if (k === "start" || k === "end")
          res = parseInt(a[k]) > parseInt(b[k]) ? 1 : -1;
        else if (k === "method" || k === "comment")
          res = a.properties[k] > b.properties[k] ? 1 : -1;
        else res = a[k] > b[k] ? 1 : -1;
        return res * o;
      };

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.proteins.sort(compare);
    }
  },
  mounted: function() {
    this.sortBy("start");
  },
  methods: {
    sortBy: function(sortKey) {
      this.reverse = this.sortKey == sortKey ? !this.reverse : false;

      this.sortKey = sortKey;
    }
  }
};
</script>

<style scoped>
a.sorting {
  font-weight: bolder;
  color: #007bff;
  cursor: pointer;
}
</style>
